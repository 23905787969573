<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="d-flex align-center font-weight-light text-h5"
      style="width: 95%;"
    >
      <div style="min-width: 25%;">
        <router-link
          v-for="crumb in breadCrumbs"
          :key="crumb.title"
          :to="crumb.to"
          class="mr-2"
        >
          {{ `${crumb.title} / ` }}
        </router-link>
      </div>
      <div
        v-if="$route.path.includes('ewc')"
        class="d-flex justify-center"
        style="width: 50%;"
      >
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              :class="`${$route.path === '/ewc/' ? 'active-nav-btn' : '' }`"
              :text="$route.path !== '/ewc/'"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/' })"
            >
              <v-icon>
                {{ mdiHomeAssistant }}
              </v-icon>
            </v-btn>
          </template>
          <span>Equipment Grid/List View</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="ewcToolbarAddEquipmentButton"
              :disabled="disableEwc"
              color="primary"
              :class="`${$route.path === '/ewc/add-equipment/' ? 'active-nav-btn' : '' }`"
              :text="$route.path !== '/ewc/add-equipment/'"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/add-equipment/' })"
            >
              <v-icon>
                {{ mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>Add Equipment</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="disableEwc"
              color="primary"
              :text="!$route.path.includes('requests')"
              :class="`${$route.path.includes('requests') ? 'active-nav-btn' : '' }`"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/requests/' })"
            >
              <v-badge
                :color="pendingRequests > 0 ? 'blue lighten-2' : 'transparent'"
                :content="pendingRequests"
                overlap
              >
                <v-icon>
                  {{ mdiTrayFull }}
                </v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Pending Equipment Requests</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="navigateToModels"
              :disabled="disableEwc"
              color="primary"
              :class="`${$route.path.includes('models') ? 'active-nav-btn' : '' }`"
              :text="!$route.path.includes('models')"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/models/' })"
            >
              <v-icon>
                {{ mdiPillar }}
              </v-icon>
            </v-btn>
          </template>
          <span>Models</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="navigateToSearch"
              :disabled="disableEwc"
              color="primary"
              :class="`${$route.path.includes('search') ? 'active-nav-btn' : '' }`"
              :text="!$route.path.includes('search')"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/search/' })"
            >
              <v-icon>
                {{ mdiMagnify }}
              </v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="navigateToConfigureFloor"
              :disabled="disableEwc"
              color="primary"
              :class="`${$route.path.includes('configure') ? 'active-nav-btn' : '' }`"
              :text="!$route.path.includes('configure')"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/configure-floor/' })"
            >
              <v-icon>
                {{ mdiHammer }}
              </v-icon>
            </v-btn>
          </template>
          <span>Configure Floor</span>
        </v-tooltip>
        <v-tooltip
          v-if="superAdmin"
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="navigateToAuditView"
              color="primary"
              :class="`${$route.path.includes('audit') ? 'active-nav-btn' : '' }`"
              :text="!$route.path.includes('audit')"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/audit-view/' })"
            >
              <v-icon>
                {{ mdiClipboardEditOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Audit View</span>
        </v-tooltip>
        <!-- <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="navigateToModels"
              :disabled="disableEwc"
              color="primary"
              :class="`${$route.path.includes('power') ? 'active-nav-btn' : '' }`"
              :text="!$route.path.includes('power')"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ path: '/ewc/power-and-network/' })"
            >
              <v-icon>
                mdi-power-plug
              </v-icon>
            </v-btn>
          </template>
          <span>Power And Network</span>
        </v-tooltip> -->
      </div>
      <v-spacer />
      <default-account />
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { sync } from 'vuex-pathify'
  import store from '@/store'
  import { mdiPillar, mdiTrayFull, mdiPlus, mdiHomeAssistant, mdiMagnify, mdiHammer, mdiClipboardEditOutline } from '@mdi/js'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () => import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
      DefaultDrawerToggle: () => import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
    },

    data: () => ({
      mdiPillar: mdiPillar,
      mdiTrayFull: mdiTrayFull,
      mdiPlus: mdiPlus,
      mdiHomeAssistant: mdiHomeAssistant,
      mdiMagnify: mdiMagnify,
      mdiHammer: mdiHammer,
      mdiClipboardEditOutline: mdiClipboardEditOutline,
    }),

    computed: {
      ...sync('app', [
        'drawer',
        'mini',
        'disableEwc',
      ]),
      breadCrumbs: function () {
        return store.get('app/breadCrumbs')
      },
      pendingRequests () {
        return store.get('app/pendingRequests')
      },
      superAdmin: function () {
        return store.get('user/role') === 3
      },

    },
  }
</script>
<style scoped>
.active-nav-btn {
  pointer-events: none;
}
</style>
